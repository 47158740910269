<template>
  <div class="form-container">
    <modal name="big-image"
           adaptive: true
           :height="'auto'"
    >
      <div class="d-flex align-items-center justify-content-center">
        <img :src="bigImg" class="img-fluid" alt="Responsive image">
      </div>
    </modal>
    <modal name="delete-toponym"
           :width="400"
           :height="'auto'"
           :scrollable="true"
    >
      <DelToponym />
    </modal>
    <form @submit.prevent="saveForm">
      <div v-for="(item, key) in getFormToponym" :key="key">
        <section v-if="item[2] === 'input' && !item[4]">          
          <div class="form-group top-40">
            <label class="">{{ item[1] }}</label>
            <div class="">           
              <input
                  class="form-control"
                  :class="{'is-invalid': getErrors[key]}"
                  type="text"
                  :name="key"
                  :value="item[0]"
                  @input="updateMessage"
              >
              <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
            </div>
          </div>
          
        </section>
        <section v-if="item[2] === 'checkbox' && !item[4]">
          
          <div class="form-group row top-40">
            <label class="col-sm-6">{{ item[1] }}</label>
            <div class="col-sm-6">  
              <input
                 
                  type="checkbox"
                  :name="key"
                  :checked="item[0]"
                  @input="updateChecked"
              >
              <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
            </div>
          </div>
  
        </section>
        <!-- <section v-if="item[4] === 'file'">
          <div class="check-label" v-if="getErrors.images">{{ getErrors.images[0] }}</div>
          <div class="form-group top-40">
            <label class="col-sm-12">{{ item[1] }}</label>
            <div class="upload-img row">
              <div
                  class="label img_hover col"
                  v-for="(pathImage, index) in item[0]" :key="index"
              >
                <div style="border: 1px dashed #ccc; width: 200px; height: 200px">
                  <img
                      :src="pathImage.images"
                      width="200"
                  >
                </div>
                <div class="img-interface d-flex align-items-center justify-content-center">
                  <span
                      :class="{'la la-eye': pathImage.zoom}"
                      style="cursor: pointer"
                      @click="bigImageModal(pathImage.images)"
                  ></span>
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="la la-pen zindex-1"></span>
                    <input
                        :id="pathImage.id"
                        multiple
                        accept="image/*"
                        type="file"
                        :ref="'file' + index"
                        @change="handleFileUpload(index)"
                    >
                  </div>
                  <span
                      style="cursor: pointer"
                      :class="{'la la-close': pathImage.close}"
                      @click="delImages({pathImage: pathImage.images, idImage: pathImage.id})"
                  ></span>
                </div>

              </div>
            </div>
          </div>
        </section> -->
        <section v-if="item[2] === 'select'">
          <section v-if="key === 'priority'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>  
              <div class="col-sm-6">            
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Приоритет"
                >
                  <el-option
                  v-for="item in 200"
                  :key="item"
                  :label="`${item}`"
                  :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
              </div>
              
            </div>
          </section>
          <section v-if="key === 'region_id'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>  
              <div class="col-sm-6">            
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Регион"
                >
                  <el-option
                  v-for="item in getSelectData['region']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
              </div>
              
            </div>
          </section>
          <!-- <section v-if="key === 'toponym_id'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>      
              <div class="col-sm-6">        
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Топоним"
                >
                  <el-option
                  v-for="item in getSelectData['toponyms']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
              </div>
            </div>
          </section> -->
          <section v-if="key === 'toponym_id'">
            <div class="form-group row">
              <label class="col-sm-3">{{ item[1] }}</label>      
              <div class="col-sm-9">        
                <el-cascader
                  class="w-100"
                  v-model="item[0]"
                  placeholder="Топоним"
                  :options="getSelectData['toponyms-tree']"
                  :props="{
                    value: 'id',
                    label: 'name_toponym',
                    children: 'children_recursive',
                    leaf: 'id',
                    checkStrictly: true
                  }"
                  filterable
                  clearable
                  :show-all-levels="false"
                >
                </el-cascader>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
              </div>
            </div>
          </section>
          
          <section v-if="key === 'category_id'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>
              <div class="col-sm-6">
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Категория"
                >
                  <el-option
                  v-for="item in getSelectData['category']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>            
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>  
              </div>
            </div>
          </section>
          <section v-if="key === 'object_type_id'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>
              <div class="col-sm-6">
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Тип объекта"
                >
                  <el-option
                  v-for="item in getSelectData['object_type']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>  
              </div>
            </div>
          </section>
          <section v-if="key === 'morpheme_id'">
            <div class="form-group row">
              <label class="col-sm-6">{{ item[1] }}</label>
              <div class="col-sm-6">
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  placeholder="Морфема"
                >
                  <el-option
                  v-for="item in getSelectData['morpheme']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>  
              </div>
            </div>
          </section>
          <section v-if="key === 'related_toponyms'">
            <div class="form-group row">
              <label class="col-sm-4">{{ item[1] }}</label>
              <div class="col-sm-8">
                <el-select
                  class="w-100"
                  :class="{'is-invalid': getErrors[key]}"
                  v-model="item[0]"
                  filterable
                  multiple
                  placeholder="Связанные объекты"
                >
                  <el-option
                  v-for="item in getSelectData['toponyms']"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>  
              </div>
            </div>
          </section>
          
        </section>
        <section v-if="item[2] === 'textarea'">
          
          <div class="form-group">
            <label class="">{{ item[1] }}</label>
            <div class="">    
              <editor
                :class="{'is-invalid': getErrors[key]}"
                v-model="item[0]"
                maxlength="2000"
                placeholder="Не больше 2000 символов"
                rows="2"
                :api-key="apiKey"
                :init="{
                  language: 'ru',
                  toolbar:
                    'undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist checklist | link',
                  plugins: 'image link advlist lists media',
                }"
              />
              <div class="invalid-feedback" v-if="getErrors[key]">{{ getErrors[key][0] }}</div>
            </div>
          </div>
        </section>
      </div>
      <div class="btn-toponym">
        <BaseButton
          :loading="isLoading"
          :disabled="isLoading"
        > Сохранить изменения
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import DelToponym from "./DelToponym/DelToponym";
  import Editor from "@tinymce/tinymce-vue";

  export default {
    name: 'AddToponym',
    components: {DelToponym, Editor},
    computed: {
      ...mapGetters([
        'getImagePreview',
        'getOrderData',
        'getActions',
        'getFormToponym',
        'getSelectData',
        'getErrors',
        'getFile',
      ]),
    },
    data() {
      return {
        bigImg: '',
        isLoading:false
      }
    },
    methods: {
      ...mapMutations([
        'setNameForm',
        'UPDATE_MESSAGE',
        'ADD_FORM_DATA',
        'ADD_FILE_IMAGE',
        'APPEND_FORM_DATA',
        'CLEAR_ERROR',
        'UPDATE_IMG',
        'SET_ORDER_DATA'
      ]),
      ...mapActions(['UPDATE_TOPONYM']),
      updateMessage(e) {
        const name = e.target.getAttribute('name')
        if (this.getErrors[name]) {
          this.clearError(name)
        }
        this.UPDATE_MESSAGE({val: e.target.value, name})
      },
      updateChecked(e) {
        const name = e.target.getAttribute('name')
        if (this.getErrors[name]) {
          this.clearError(name)
        }
        this.UPDATE_MESSAGE({val: Number(e.target.checked), name})
      },
      saveForm() {

        if (this.isLoading) return

        this.isLoading = true

        if(this.getOrderData.id === undefined) {
          this.$store.commit('SET_ACTIONS', {
            method: 'post',
            url: '/toponyms',
            commitName: 'setObjectData',
          })


        } else {
          this.$store.commit('SET_ACTIONS', {
            method: 'post',
            _method: 'put',
            url: '/toponyms/'+this.getOrderData.id,
            commitName: 'setObjectData',
          })
        }
        this.ADD_FORM_DATA()
        if (this.getFile) {
          this.APPEND_FORM_DATA()
        }

        this.UPDATE_TOPONYM({
          method: this.getActions.method,
          url: this.getActions.url,
          commitName: this.getActions.commitName,
          notify: this.$notify,
          modal: this.$modal,
          modalName: 'edit-toponym'
        }).then(() => {
          this.isLoading = false
          this.$emit('onUpdate')
        })

      },
      handleFileUpload(index) {
        this.ADD_FILE_IMAGE(
            {
              file: [...this.$refs['file' + index][0].files],
              id: +this.$refs['file' + index][0].id,
              index: index
            }
        )
        const inputFile = this.$refs['file' + index][0]
        if (inputFile && !this.getErrors.images) {
          let reader = new FileReader()
          const ctx = this
          reader.addEventListener('load', function () {
            ctx.UPDATE_IMG(reader.result)
          })
          reader.readAsDataURL(inputFile.files[0])
        }
      },
      clearError(name) {
        this.CLEAR_ERROR(name)
      },
      bigImageModal(img) {
        this.$modal.show('big-image')
        this.bigImg = img
      },
      delImages({pathImage, idImage}) {
        this.$store.commit('SET_ACTIONS', {
          method: 'post',
          _method: 'put',
          urlApi: 'images',
          commitName: 'deleteImages',
          text: 'Вы точно хотите удалить это фото?',
          order: pathImage.split('/').pop(),
          id: this.getOrderData.id,
          idImage: idImage
        })
        this.$store.commit('addDelImageFormData')
        this.$modal.show('delete-toponym')
      }
    },
  }
</script>

<style lang="scss">
  .top-40 {
    margin-top: 40px;
  }
  .form-container {
    margin: 0 35px 0 35px;
  }
  .check-label {
    color: red;
    text-align: center;
  }
  .btn-toponym {
    margin: 70px 0 100px 0;
    text-align: center;
  }
  .btn-edit-toponym {
    width: 30%;
  }
  .upload-img input[type=file] {

    z-index: 10;
    width: 20px;

    opacity: 0;
    position: absolute;
    overflow: hidden;
    cursor:pointer;
  }
  ::-webkit-file-upload-button {
    cursor:pointer;
  }
  .upload-img .label {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 0;
    background: #fff;
  }
  .label {
    position: relative;
  }
  .img-interface {
    position: absolute;
    background: rgba(46, 52, 72, 0.76);
    opacity: 0;
    width: 200px;
    height: 200px;
    transition: 0.4s !important;
  }
  .img-interface div {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;
  }
  .img_hover:hover .img-interface {
    opacity: 1 !important;
  }
  .upload-img img {
    position: absolute;
    cursor: pointer;
  }
  .img-interface span {
    color: #fff;
    margin: 10px;
    font-size: 25px;
  }

  .el-select.is-invalid .el-input__inner{
    border: 1px solid #F64E60;
    border-radius: 0.42rem
  }
</style>

