<template>
  <div class="card card-custom">
    <modal
      name="edit-toponym"
      @before-close="beforeClose"
      :width="800"
      :height="'auto'"
      :scrollable="true"
    >
      <AddToponym 
        @onUpdate="updateData"
      />
    </modal>

    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Топонимы</h3>
      </div>
      <div class="card-toolbar">
        <button @click="addNewToponym" class="btn btn-primary">
          Добавить топоним
        </button>
      </div>
    </div>
    <div v-if="isData" class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <v-client-table
          :columns="columns"
          v-model="getData"
          :options="options"
          ref="table"
        >
          <div slot="active" slot-scope="props">
              <span v-if="props.row.active == 0" class="text-danger">Не активен</span>
              <span v-if="props.row.active == 1" class="text-success">Активен</span>
            </div>
            <div slot="moderated" slot-scope="props">
              <span v-if="props.row.moderated == 0" class="text-warning">На модерации</span>
              <span v-if="props.row.moderated == 1" class="text-success">Принят</span>
            </div>
          <div slot="action" slot-scope="props">
            <EditBtn v-tooltip="'Изменить'" @click="editOrder(props.row)" />
            <DeleteBtn v-tooltip="'Удалить'" @click="deleteOrder(props.row)" />
          </div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditBtn from "../../components/table/EditBtn";
import DeleteBtn from "../../components/table/DeleteBtn";
import AddToponym from "../../components/table/AddToponym";
const DelToponym = () => import("../../components/table/DelToponym/DelToponym");
import { vueTableMixin } from "../mixins/vueTableMixin";

export default {
  name: "Toponym",
  mixins: [vueTableMixin],
  computed: {
    ...mapGetters(["getOrderData", "getData"]),
  },
  data() {
    return {
      isData: false,
      columns: [
        "name_toponym",
        "parent",
        "region",
        "category",
        "object_type",
        "author",
        "updated_at",
        'active',
        'moderated',
        "action",
      ],
      options: {
        headings: {
          name_toponym: 'Топоним',
          region: "Регион",
          category: "Разряд",
          object_type: "Тип объекта",
          author: "Автор",
          updated_at: "Дата",
          action: "Править",
          active: 'Активен',
          moderated: 'Модерации',
          parent: 'Относится к'
        },
        filterable: true,
        sortIcon: {
          base: "la",
          up: "la-long-arrow-up text-success pt-1",
          down: "la-long-arrow-down text-success pt-1",
          is: "la-arrows-v text-muted pt-1",
        },
      },
    };
  },
  methods: {
    getToponyms(url) {
      this.$store.commit("updateIsLoading", true);
      axios
        .get("/toponyms")
        .then((toponyms) => {
          if (toponyms.data.toponym.length !== 0) {
            this.$store.commit("setData", toponyms.data.toponym);
          }
          this.$store.commit("setSelectData");
          this.$store.commit("updateAuthenticated", true);
          this.isData = true;
          this.$store.commit("updateIsLoading", false);
        })
        .catch((error) => {
          if (error.response.data.message === "Unauthenticated.") {
            this.$router.push({ name: "Login" });
          }
        });
    },
    editOrder(orderData) {
      this.$modal.show("edit-toponym");
      this.$store.commit("SET_ORDER_DATA", orderData);
      this.$store.commit("setNameForm", this.getOrderData);
    },
    deleteOrder(order) {
      this.$store.commit("SET_ACTIONS", {
        method: "delete",
        urlApi: "toponyms",
        commitName: "delToponym",
        text: "Вы точно хотите удалить эту запись",
        id: order.id,
      });

      this.$modal.show(DelToponym, {}, {
        width:400,
        height: 'auto',
        scrollable: true
      })
    },
    hide() {
      this.$modal.hide("edit-toponym");
    },
    addNewToponym() {
      this.$store.commit("ADD_NEW_TOPONYM");
      this.$store.commit("SET_ORDER_DATA", this.getData[0]);
      this.$store.commit("setNameForm", this.getOrderData);
      this.$modal.show("edit-toponym");
    },
    beforeClose() {
      if (this.getData[0].author === "") {
        this.$store.commit("BEFORE_CLOSE");
      }
    },
    updateData(){
      this.getToponyms();
    }
  },
  mounted() {
    this.getToponyms();
  },
  components: {
    AddToponym,
    DelToponym,
    DeleteBtn,
    EditBtn,
  },
};
</script>

<style>
.card-body {
  background: #fafafa;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip-inner {
  background: white;
  color: #9a9aa5;
  font-weight: bold;
  border-radius: 2px;
  padding: 5px 10px 4px;
}
.tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: white;
}
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"] {
  margin-left: 5px;
}
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.add-toponym {
  cursor: pointer;
}
.add-toponym:hover {
  color: black;
}
</style>
